import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'config',
        loadChildren: () => import('./modules/config/config.routes').then(r => r.CONFIG_ROUTES)
    },
    {
		path: 'proforma',
		loadChildren: () => import('./modules/Proforma/proforma.routes').then(r => r.PROFORMA_ROUTES)
	},
	{ 
        path: '**',
        redirectTo: 'proforma',
        pathMatch: 'full'
    }
];
